import { FormikErrors } from 'formik';
import first from 'lodash/first';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';

import {
    Props as StrengthValidatorProps,
    Rule,
} from '@legacy-components/StrengthValidator';

import { ApolloError } from 'apollo-client';
import {
    RequestResetPasswordValue,
    ResetPasswordValues,
    SignInFormValues,
    SignUpBadInputError,
} from './typings';
import { ErrorCode, FormattedError, Nullable } from '../common/typings';

export function computeStrengthValidatorRules(strong: boolean): Rule[] {
  const MIN_CHARACTER_LENGTH = strong ? 16 : 8;
  const HAS_LOWER_REGEX = /[a-zà-ùü/]/;
  const HAS_UPPER_REGEX = /[A-ZÀ-ÙÜ]/;
  const HAS_NUMBER_REGEX = /[0-9]/;
  const HAS_SPECIAL_CHAR_REGEX = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  const rules: Rule[] = [
    {
      validate: (password: string): boolean =>
        isLength(password, { min: MIN_CHARACTER_LENGTH }),
      placeholderKey: 'characterMinLength',
      messageProps: {
        id: 'authentication.signUp.password.characterMinLength',
        defaultMessage:
          '{characterMinLength, plural, one {1 character} other {{characterMinLength} characters}}.',
        values: {
          characterMinLength: MIN_CHARACTER_LENGTH,
        },
      },
    },
    {
      validate: (password: string): boolean => HAS_UPPER_REGEX.test(password),
      placeholderKey: 'uppercase',
      messageProps: {
        id: 'authentication.signUp.password.uppercase',
        defaultMessage: 'uppercase',
      },
    },
    {
      validate: (password: string): boolean => HAS_LOWER_REGEX.test(password),
      placeholderKey: 'lowercase',
      messageProps: {
        id: 'authentication.signUp.password.lowercase',
        defaultMessage: 'lowercase',
      },
    },
    {
      validate: (password: string): boolean => HAS_NUMBER_REGEX.test(password),
      placeholderKey: 'number',
      messageProps: {
        id: 'authentication.signUp.password.number',
        defaultMessage: 'number',
      },
    },
    {
      validate: (password: string): boolean =>
        strong ? HAS_SPECIAL_CHAR_REGEX.test(password) : true,
      placeholderKey: 'specialChar',
      messageProps: {
        id: 'authentication.signUp.password.specialChar',
        defaultMessage: ', a special character',
      },
      disabled: !strong,
    },
  ];
  return rules;
}

export const strengthValidatorProps = (
  strong = false,
): StrengthValidatorProps => {
  return {
    messageProps: {
      id: 'authentication.signUp.password.newStrengthValidator',
      defaultMessage:
        'Use at least {characterMinLength} with an {uppercase}, a {lowercase}{specialChar} and a {number}',
    },
    rules: computeStrengthValidatorRules(strong),
  };
};

export function validateSignInForm(
  values: SignInFormValues,
): FormikErrors<SignInFormValues> {
  const errors: FormikErrors<SignInFormValues> = {};

  if (!isEmail(values.email)) {
    errors.email = 'Invalid email';
  }

  if (!values.password) {
    errors.password = 'Empty password';
  }

  return errors;
}

export function validateResetPassword(
  values: ResetPasswordValues,
  strong = false,
): FormikErrors<ResetPasswordValues> {
  const errors: FormikErrors<ResetPasswordValues> = {};

  if (
    computeStrengthValidatorRules(strong).some(
      (rule: Rule): boolean => !rule.validate(values.newPassword),
    )
  ) {
    errors.newPassword = 'Invalid password strength';
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Does not match new password';
  }

  return errors;
}

export function validateEmail(
  value: RequestResetPasswordValue,
): FormikErrors<RequestResetPasswordValue> {
  const errors: FormikErrors<RequestResetPasswordValue> = {};

  if (!isEmail(value.email)) {
    errors.email = 'Invalid email';
  }

  return errors;
}

export function getSignUpErrorTranslationId(
  signUpError: SignUpBadInputError,
): string {
  switch (signUpError.code) {
    case ErrorCode.INVALID_PUBLIC_EMAIL: {
      return 'authentication.signUp.email.error.input.public';
    }
    default: {
      return 'authentication.signUp.email.error.input.default';
    }
  }
}

export type SignUpErrorType = 'email' | 'registration_code';

export const mapErrorTypeToErrorCode: {
  [key in SignUpErrorType]: ErrorCode;
} = {
  registration_code: ErrorCode.REGISTRATION_CODE_INVALID,
  email: ErrorCode.UNRECOGNIZED_EMAIL,
};

export function isSignUpErrorOfCertainType(
  error: ApolloError,
  type: SignUpErrorType,
): boolean {
  const inputError: FormattedError | undefined = (
    error.graphQLErrors || []
  ).find(
    (gqlError: FormattedError) => gqlError.code === ErrorCode.BAD_USER_INPUT,
  );

  if (inputError?.context?.[type]) {
    const signUpError = first(
      inputError.context[type] as SignUpBadInputError[],
    );
    if (signUpError?.code === mapErrorTypeToErrorCode[type]) {
      return true;
    }
  }
  return false;
}

export function getRegistrationCodeErrorTranslationId(
  signUpErrorMessage: Nullable<ErrorCode>,
): string {
  switch (signUpErrorMessage) {
    case ErrorCode.UNRECOGNIZED_EMAIL:
      return 'authentication.signUp.email.error.input.public';
    case ErrorCode.REGISTRATION_CODE_INVALID:
      return 'authentication.signUp.registrationCode.error';
    case ErrorCode.NETWORK_REQUEST_ERROR:
    case ErrorCode.NETWORK_REQUEST_ERROR_WEB:
    case ErrorCode.NETWORK_TIMEOUT:
      return 'common.error.networkError';
    default:
      return 'common.error.unexpected';
  }
}
